export const JobsData = [
  {
    id: 0,
    title: "Information Security Consultant",
    type: "Full-Time",
    location: "Birmingham",
    date: "28 Feb, 2023",
    description: `We @ISS Consultancy Ltd, are looking for experienced Information Security Consultant having a good sense of product engineering to help deliver solutions far above industry standards. Are you the one? If yes, then, it's a great opportunity for you!
As an Information Security Consultant for ISS Consultancy Ltd.’s products, 
`,
    skillsNeeded: [
      "Responsible for providing security consultancy as part of the centre of excellence for information security management, offering security advice and practical assistance on information security risk and control matters throughout the organisation.",
      "Help to develop and establish IT Security procedures and standards by creating detailed process documentation.",
      "Develop, design, and implement security service management frameworks.",
      "Analyse and recommend guidelines to resolve issues or security concerns of tools/services",
      "Ensure IT Operations security at optimum level, preventive measures and process in place",
      "Maintaining a knowledge of information security emerging risks and developments in control best practice",
      "Develop security and privacy training and awareness requirements and packages",
      "Manage regional cyber security policies and standards",
      "Complete risk assessments, treatment of actions and planning, security awareness and training",
      "Conduct information security and compliance assessments and/or analysis.",
    ],
    education: [
      "We prefer a bachelors degree in computer’s. However, If you prove you have extraordinary and right skills, we value them and formal education doesn't matter to us.",
    ],
    experience: [
      "We prefer you to have at least 10 years experience in the above described tools and technologies. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " Annual salary of £26,000 (Adjustable as per the qualifications and experience).",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
  {
    id: 1,
    title: "Technical System Architect",
    type: "Full-Time",
    location: "Birmingham",
    date: "4 May, 2022",
    description: `We @ISS Consultancy Ltd, are looking for an experienced Oracle Cloud Fusion Consultant who has excelled in implementation and help deliver solutions far above industry standards. 
    Are you the one? If yes, then, it's a great opportunity for you! 
    The ideal candidate cherishes the challenge of designing features across the HCM Cloud Application. 
    As a Implementation Consultant, you will configure, develop and implement new ideas for ISS Consultancy Ltd's projects. We’re scaling quickly, so we’re recruiting teammates who share our core values, 
    know how to get the job done, and would add a lot to our diverse culture.`,
    skillsNeeded: [
      "Provide Oracle HCM Cloud Fusion functional/technical consulting services by acting as subject matter expert and leading clients through the entire cloud application services implementation lifecycle for Oracle HCM Cloud Fusion projects",
      "Identify functionality gaps in Oracle HCM Cloud Fusion, and build extensions for them",
      "Advise client on options, risks, and any impacts on other processes or systems",
      "Conduct Functional/Technical Design Workshops for Oracle HCM Cloud Fusion projects",
      "Assist client in preparing validation scripts, testing scenarios and develop test scripts for Oracle HCM Cloud Fusion projects",
      "Support clients with the execution of test scripts",
      "Validate configuration and data for Oracle HCM Cloud Fusion projects",
    ],
    mustHave: [
      "Hands on experience with Oracle Cloud Fusion Version 13",
      "Prior experience in leading HCM implementation and upgrades",
      "Ability to work on all project phases: Project Requirement Gathering, Fit/Gap Analysis, Configuration, Testing, Deployment and Support.",
      "Must be a team player.",
    ],
    education: [
      "We prefer a bachelors degree in computer’s. However, If you prove you have                       extraordinary and right skills, we value them and formal education doesn't matter to us.",
    ],
    experience: [
      "We prefer you to have at least 2 years experience in the above described tools and technologies. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " Annual salary of £30,000 (Adjustable as per the qualifications and experience).",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
  {
    id: 2,
    title: "Full Stack Developer (10 Positions)",
    type: "Full-Time",
    location: "Birmingham",
    date: "4 May, 2022",
    description: `We @ISS Consultancy Ltd, are looking for passionate programming geeks to join as our colleagues. We’re a startup with a great vision and values, so you’ll be able to have a huge impact.
Are you a JavaScript ninja and have a good sense of product engineering? If yes, then, it’s a great opportunity for you!
As a Product Developer, you will design, develop and implement new ideas for ISS Consultancy Ltd’s products.
`,
    skillsNeeded: [
      "Responsible for building responsive and elegant web/mobile applications.",
      "Working with designers, product managers to collaboratively own the front-end and back-end layer to deliver stellar end-user experiences.",
      "Advise client on options, risks, and any impacts on other processes or systems",
      "Test, debug and maintain the application software throughout the product lifecycle.",
      "Architect, develop and maintain reusable components.",
      "Train other team members on your specialities to ensure a cross functional team.",
    ],
    mustHave: [
      "Hands-on experience in front-end technologies such as JavaScript, HTML , CSS, Bootstrap and React.js.",
      "Proficiency in React.js (workflow and methodology) and Redux.",
      "Experience with Node.js based backend libraries.",
      "Experience with complex SQL and NoSQL databases.",
      "Bonus points if have knowledge/experience in kubernetes, containers, and microservices.",
      "Familiarity with code versioning tools such as Git",
      "Git knowledge is a plus.",
      "Must be a team player.",
      "Good time-management skills",
      "Great interpersonal and communication skills.",
    ],
    education: [
      "We prefer a bachelors degree in computer science. However, If you prove you have extraordinary and right skills, we value them and formal education doesn’t matter to us.",
    ],
    experience: [
      "We prefer you to have at least 3 years experience in the above described tools and technologies. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " Annual salary of £27,000 (Adjustable as per the qualifications and experience).",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
  {
    id: 3,
    title: "Web Designer (3 positions)",
    type: "Full-Time",
    location: "Birmingham",
    date: "4 May, 2022",
    description: `We @ISS Consultancy Ltd, are looking for Web Developers. We’re a startup with a great vision and values, so you’ll be able to have a huge impact.`,
    skillsNeeded: [
      "Hands-on experience in HTML , CSS, and Bootstrap",
      "Experience in Web Application Development using PHP/MySQL",
      "Proficiency in JavaScript.",
      "Knowledge of web services",
      "experience in search engine optimization (SEO)",
      "experience in designing sample page layouts including text size and colors",
      "Able to work in a startup environment",
    ],
    mustHave: [
      "Hands-on experience in HTML , CSS, and Bootstrap",
      "Experience in Web Application Development using PHP/MySQL",
      "Proficiency in JavaScript.",
      "Knowledge of web services",
      "experience in search engine optimization (SEO)",
      "experience in designing sample page layouts including text size and colors",
      "Able to work in a startup environment",
    ],
    education: [
      "We prefer a bachelors degree in computer science. However, If you prove you have extraordinary and right skills, we value them and formal education doesn’t matter to us.",
    ],
    experience: [
      "We prefer you to have at least 2 years experience in the above described tools and technologies. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " Annual salary of £21,000 (Adjustable as per the qualifications and experience).",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
  {
    id: 4,
    title: "Office Manager",
    type: "full-time",
    location: "Birmingham",
    date: "4 May, 2022",
    description: `We @ISS Consultancy Ltd, are looking for experienced Manager, and have a good
sense of People Management skill. Are you the one? If yes, then, it’s a great opportunity for you !
The ideal candidate cherishes the challenge of understanding us, our vision and
efficiently managing the office, by complying with various legislations. We’re scaling quickly, so we’re recruiting teammates who share our core values, know how to get the job done, and would add a lot to our diverse culture. Here’s what you will be doing :
Overseeing general office operation
Greeting visitors, answering incoming phone calls, emails and delivering world- class service to our clients
Coordinating appointments and meetings and managing staff calendars and
schedules
Supervising, mentoring, training, and coaching our office staff and delegating
assignments to ensure maximum productivity
Coordinating domestic and international travel, including flight, hotel, and car
rental reservations
Purchasing office supplies and equipment and maintaining proper stock levels
Producing reports, composing correspondence, and drafting new contracts
Creating presentations and other management-level reports
Man hour budgeting
Managing and enforcing HR Policies
Enforcing compliance of legal requirements (Preferable to have a legal
background)
`,
    skillsNeeded: [
      "Studied in Law, Business, IT, Economics, Management or a similar field of study",
      "Professional experience in sales, marketing and development",
      "Comprehensive IT skills (Word, Excel, PPT and any collaborative tool)",
      "Good communication skills and team spirit",
      "Foreign language skills and – ideally – international experience",
      "A proactive, responsible way of working, even under pressure",
      "Good time-management skills",
      "Excellent people management skills",
    ],
    mustHave: [],
    education: [
      "We prefer a degree or diploma in Law, Business, IT, Economics, Management or a similar field of study. However, If you prove you have extraordinary and right skills, we value them and formal education doesn’t matter to us",
    ],
    experience: [
      "We prefer you to have at least 2 years experience in the above described segment. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " National Minimum Wage",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
  {
    id: 5,
    title: "Business Development Manager",
    type: "Full-Time",
    location: "Birmingham",
    date: "4 May, 2022",
    description: `We @ISS Consultancy Ltd, are looking for experienced Business Development
Manager, a Mind / Market Reading ninja and have a good sense of Market Dynamics, understand our solutions to get projects from the industry. Are you the one? If yes,
then, it’s a great opportunity for you!
The ideal candidate cherishes the challenge of understanding us, our vision and
come up with proper strategy by conducting market analyses and developing
existing business areas and new markets. We’re scaling quickly, so we’re recruiting teammates who share our core values, know how to get the job done, and would add a lot to our diverse culture. Here’s what you will be doing 
`,
    skillsNeeded: [
      "Design and implementation of new strategies in marketing, sales and customer service by understanding Human Behavioral Pattern",
      "Development of new markets, customer groups and partners",
      "Market and competitor analyses",
      "Communication with (potential) customers and partners",
      "Exchange and cooperation with the development department",
      "Training of employees with regard to new strategic targets",
      "Participation in trade fairs and conferences",
    ],
    mustHave: [
      "Deep Knowledge & Extensive Experience in Human Behavior Pattern",
      "Studies in Business, IT, Economics, Sales, Marketing or a similar field of study",
      "Professional experience in sales, marketing and development",
      "Comprehensive IT skills (Word, Excel, PPT and any collaborative tool)",
      "Good communication skills and team spirit",
      "Foreign language skills and – ideally – international experience",
      "A proactive, responsible way of working, even under pressure",
      "Good time-management skills.",
    ],
    education: [
      "We prefer a bachelors degree in Business, IT, Economics, Sales, Marketing or a similar field of study. However, If you prove you have extraordinary and right skills, we value them and formal education doesn’t matter to us.",
    ],
    experience: [
      "We prefer you to have at least 8 years experience in the above described segment. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " Annual salary of £75,000 (Adjustable as per the qualifications and experience).",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
  {
    id: 6,
    title: "Software Tester (3 positions)",
    type: "Full-Time",
    location: "Birmingham",
    date: "4 May, 2022",
    description: `ISS Consultancy Ltd is looking for experienced Software Tester’s with an eye for spotting the bug and giving a headache to our development team.
The perfect candidate shares our passion for excellence and approaches quality testing with a user-first mentality. Our quality team is responsible for maintaining the unique and high standards at ISS Consultancy Ltd.
You must also have expertise writing automated test, reviewing code, contribute to the automated test framework, developing tools to assist manual and exploratory testing, and solving tricky problems. At ISS Consultancy Ltd, the team, process, and tools we use are critical to our success.
You should enjoy continuously exploring and challenging the platform as it grows. We release new builds at a rapid rate, so time management and self-prioritization are a must. You will work on reliability across different browsers, platforms and various environments. A well-developed set of communication skills is also important for successfully fulfilling these responsibilities and many others. Multi-Lingual and Multi-Cultural will only be an added asset.
We’re scaling quickly, so we’re recruiting teammates who share our core values, know how to get the job done, and would add a lot to our diverse culture.
`,
    skillsNeeded: [
      "You have no problem developing automation frameworks and increasing test coverage via automation.",
      "You will analyze defects, understand the root cause, work with the development team to resolve issues.",
      "You thrive on learning new technologies, and are fearless when it comes to delving out of your comfort zones.",
      "You should be able to adapt quickly to meet the needs of our growing business environment. We have an React Frontend and Nodejs Backend, so you need to have a solid understanding of how to operate with this stack.",
      "You’ve been challenged in the past and proven that you’re capable of quite a lot.",
      "You are OK taking on new challenges and have a passion about building an amazing product more than almost anything else.",
      "You strive on proving that speed and quality have absolutely no conflicts.Staying on top of the latest software architectural trends is a passion of yours.",
    ],
    mustHave: [
      "Web manual testing experience (2+ years of exp.)",
      "API testing experience (1+ years of exp.).",
      "Experienced with Web automation testing tool: Preferably Selenium or an industry equivalent tool.",
      "Experience building scalable test infrastructure for web applications.Knowledge of web services",
      "Extremely comfortable with working in a fast-paced Agile/SCRUM environment.",
      "Skilled at explaining technical problems and issues succinctly and clearly.",
      "Experience with BDD and git version control is a big plus.",
      "Excellent written, verbal, and interpersonal communication skills.",
      "Experience writing test cases for automation (unit, e2e, integration, etc)",
      "Hands-on experience working with JavaScript and React",
      "Knowledge of test frameworks, methodologies and their corresponding tools",
    ],
    education: [
      "We prefer a bachelors degree in computer science. However, If you prove you have extraordinary and right skills, we value them and formal education doesn’t matter to us.",
    ],
    experience: [
      "We prefer you to have at least 3 years experience in the above described tools and technologies. However a strong grip on the foundation and a flair to learn will push us to consider you on a case by case basis.",
    ],
    renumeration: [
      " Annual salary of £27,000 (Adjustable as per the qualifications and experience).",
      "35 Hours/week",
      "24 Annual holiday excluding govt holidays.",
      "Uncapped hike in remuneration linked to performance.",
      "Open opportunity for professional courses and certifications (Based on availability).",
      "Work from home / Hybrid Model.",
    ],
  },
];
