import styled from "styled-components";
const calLineHt = (font, size) => {
  if (font && size) {
    const string = font.split("rem");
    const int = parseFloat(string);
    const lineHt = int * size;
    return lineHt;
  }
};

export const CardWrapper = styled.div`
  box-sizing: border-box;
  padding: 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  border-radius: 2px;
  width: ${({ width }) => width || "300px"};
  height: ${({ height }) => height || "400px"};
  background-color: ${({ bgColor }) => bgColor || "#a5b7c3"};
  color: ${({ color }) => color || "black"};
  border: ${({ isBorder }) => (isBorder ? "0.25px solid black" : "none")};
  transition: 0.5s all ease-in-out;
  &:hover {
    box-shadow: ${(props) =>
      props.isShadow ? "6px 7px 20px 8px grey" : "none"};
    -webkit-box-shadow: ${(props) =>
      props.isShadow ? "6px 7px 20px 8px grey" : "none"};
    -moz-box-shadow: ${(props) =>
      props.isShadow ? "6px 7px 20px 8px grey" : "none"};
  }
`;
//2px 2px 2px 2px #888888
export const IconWrapper = styled.div`
  padding: 0.25rem;
  background-color: ${({ bgColor }) => bgColor || "pale"};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Header = styled.h2`
  text-align: center;
  letter-spacing: 2px;
  line-height: ${({ headerFont }) => calLineHt(headerFont, 0.5) || `50px`};
  font-family: "Times New Roman";
  font-size: ${({ headerFont }) => headerFont || "1.5rem"};
  margin: 0;
`;
export const Para = styled.p`
  text-align: center;
  letter-spacing: 1.2px;
  line-height: ${({ paraFont }) => calLineHt(paraFont, 1.2) || `30px`};
  font-family: Arial;
  font-size: ${({ paraFont }) => paraFont || "1rem"};
`;
