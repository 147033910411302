import styled from "styled-components";
export const Container = styled.div`
  width: ${({ width }) => width || "200px"};
  height: ${({ height }) => height || "auto"};
  box-shadow: 1px 0.5px 1px 0.5px grey;
  border-radius: 5%;
  transition: 0.5s all ease-in-out;
  &:hover {
    transform: scale(0.9);
  }
`;
export const Picture = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5%;
`;
