import React, { useState, useEffect } from "react";
import { JobsData } from "../../data/jobData";
import JobDescription from "./JobDescription";

import JobElement from "./JobElement";
import "./Jobs.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ScrollToTop from "./ScrollToTop";

function Jobs() {
  useEffect(() => {
    handleScreen();
  }, []);
  const [jobId, setJobId] = useState(0);
  const [column, setColumn] = useState(1);
  const [isSmallScreen, setSmallScreen] = useState(false);
  // let isSmallScreen=false;
  const isColumn1 =
    !isSmallScreen || (isSmallScreen && column === 1) ? true : false;
  const isColumn2 =
    !isSmallScreen || (isSmallScreen && column === 2) ? true : false;
  const handleScreen = () => {
    if (window.innerWidth <= 700) {
      setSmallScreen(true);
    }
    if (window.innerWidth > 700) {
      setSmallScreen(false);
    }
  };
  const colOneStyle =
    isColumn1 && isColumn2 ? "jobs-col-1" : "jobs-col-1 jobs-col-1-single";
  const colTwoStyle =
    isColumn1 && isColumn2 ? "jobs-col-2" : "jobs-col-2 jobs-col-2-single";

  // const [column, setColumn] = useState(1);
  // const [column1, setColumn1] = useState("");
  // const [column2, setColumn2] = useState("");
  // const displayColumn = (colNo) => {
  //   if (isSmallScreen && colNo == column) {
  //     return `column${colNo}`;
  //   } else return "";
  // };
  // let column1 = displayColumn(1);
  // let column2 = displayColumn(2);

  // const toggleScreen = () => setSmallScreen(!isSmallScreen);
  // useEffect(() => {
  //   handleScreen();
  //   displayColumn(1);
  // }, []);
  // useEffect(() => {
  //   displayColumn(column);
  // }, [column]);
  // const handleScreen = () => {
  //   if (window.innerWidth <= 700) {
  //     setSmallScreen(true);
  //   } else {
  //     setSmallScreen(false);
  //   }
  // };
  // const toggleColumn = () => setColumn(!column);
  window.addEventListener("resize", handleScreen);

  const handleJobId = (id) => {
    setJobId(id);
    setColumn(2);
  };

  const titles = JobsData.map((element, index) => {
    return (
      <JobElement
        key={index}
        element={element}
        onSelectJob={(id) => {
          handleJobId(id);
        }}
        isSelected={jobId === element.id ? true : false}
      />
    );
  });

  return (
    <div className="jobs-container">
      <div className="jobs-row">
        {isColumn1 && (
          <div className={colOneStyle}>
            <div className="jobs-col-1-title">Jobs Title</div>
            <div className="jobs-col-1-content">{titles}</div>
          </div>
        )}

        {isColumn2 && (
          <div className={colTwoStyle}>
            {isSmallScreen && (
              <AiOutlineArrowLeft
                onClick={() => {
                  setColumn(1);
                }}
                style={{
                  fontSize: "2rem",
                  color: "black",
                  position: "sticky",
                  position: "-webkit - sticky",
                  top: "0",
                  borderBottom: "2px",
                }}
              />
            )}
            <div className="jobs-col-2-content">
              <ScrollToTop>{<JobDescription jobId={jobId} />}</ScrollToTop>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Jobs;
