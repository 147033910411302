import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { MenuItem, Select, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ContactForm.css";
import { sendData } from "../../axios";
function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [query, setquery] = useState("general");
  const [message, setMessage] = useState("");

  const notify = () => toast.info("Query submitted successfully");
  const handleName = (event) => setName(event.target.value);

  const handleEmail = (event) => setEmail(event.target.value);

  const handlePhone = (event) => setPhone(event.target.value);

  const handleQuery = (event) => setquery(event.target.value);

  const handleMessage = (event) => setMessage(event.target.value);
  const submitData = () => {
    try {
      sendData("query", {
        name,
        email,
        phone,
        query,
        message,
      });
      notify();
      setName("");
      setEmail("");
      setPhone("");
      setquery("");
      setMessage("");
    } catch (err) {
    }
  };

  return (
    <div className="contactForm-container">
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <FormControl
          variant="standard"
          style={{ marginBottom: "10px", fontSize: "1rem" }}
        >
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input id="name" value={name} onChange={handleName} />
        </FormControl>
        <FormControl variant="standard" style={{ marginBottom: "10px" }}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input id="email" value={email} onChange={handleEmail} />
        </FormControl>
        <FormControl variant="standard" style={{ marginBottom: "10px" }}>
          <InputLabel htmlFor="phone">Phone</InputLabel>
          <Input id="phone" value={phone} onChange={handlePhone} />
        </FormControl>
      </div>
      <FormControl
        size="large"
        style={{ padding: "10px", marginBottom: "10px" }}
      >
        <InputLabel id="queryType">Query</InputLabel>
        <Select
          labelId="queryType"
          id="queryTypeSelect"
          value={query}
          label="Query"
          onChange={handleQuery}
        >
          <MenuItem value={"general"}>General</MenuItem>
          <MenuItem value={"project"}>Projects</MenuItem>
          <MenuItem value={"training"}>Training & Internship</MenuItem>
          <MenuItem value={"jobOpenings"}>Job Openings</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="outlined-multiline-static"
        label="Message"
        multiline
        rows={4}
        value={message}
        style={{ padding: "10px", marginBottom: "10px" }}
        onChange={handleMessage}
      />

      <div style={{ textAlign: "center", padding: "10px" }}>
        <button className="contactForm-btn" onClick={submitData}>
          Submit
        </button>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default ContactForm;
