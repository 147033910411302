import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Card from "../elements/Card";

import six from "../../images/6.jpg";
import { FaBeer } from "react-icons/fa";
import { DiCode } from "react-icons/di";
import { Button } from "../elements/Button";
import Image from "../elements/Image";
import Quote from "../elements/Quote";
const header = "Discover our world of technology";
const para =
  "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC";

function Home() {
  return (
    <>
      <section>
        <div className="home">
          <h1>
            Discover Our <br /> Digital Innovations <br /> and Services
          </h1>
          <p>
            Offering effective solutions to achieve your business <br /> goals
            remarkably at its best.
          </p>
          <Button moveTo="/services" color="rgb(7, 21, 86)" isOutline={true}>
            explore
          </Button>
          {/* <button>
            <Link to="/services" className="explore">
              Explore
            </Link>
          </button> */}
        </div>
      </section>
    </>
  );
}

export default Home;
