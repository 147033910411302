import React, { useEffect, useState, useRef } from "react";
import { JobsData } from "../../data/jobData";
import { IoIosTimer } from "react-icons/io";
import { FaSuitcase } from "react-icons/fa";
import { BiCurrentLocation } from "react-icons/bi";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./JobDescription.css";
import { sendData } from "../../axios";
function JobDescription({ jobId }) {
  const [job, setJob] = useState(JobsData[0]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const jobDescription = useRef(null);
  const scrollTop = (elementRef) => {
    window.scrollTo(0, elementRef.current.offsetTop);
  };
  useEffect(() => {
    setJob(JobsData[jobId]);
    scrollTop(jobDescription);
  }, [jobId]);
  const {
    title,
    type,
    location,
    date,
    description,
    skillsNeeded,
    mustHave,
    experience,
    education,
    renumeration,
  } = job;

  const [isModalOpen, setModalOpen] = useState(false);
  const notify = () => toast.info("You've appplied the job successfully");
  const applyJob = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const submitJob = async (e) => {
    e.preventDefault();
    const response = await sendData("job", {
      name,
      email,
      phone,
      message,
      jobId,
    });
    // popup or alert
    closeModal();
    notify();
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const modalStyles = {
    overlay: {},
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(82, 103, 194, 0.8)",
      height: "auto",
      width: "50%",
      borderRadius: "10px",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",

      padding: "10px",
    },
  };
  return (
    <div ref={jobDescription} className="jobDescription-container">
      <div className="jobDescription-title">
        <div className="jobDescription-title-text">
          <h2>{title}</h2>
          <div className="jobDescription-title-text-icons">
            <span>
              <IoIosTimer className="jobDescription-icon" /> {date}
            </span>
            <span>
              <FaSuitcase className="jobDescription-icon" /> {type}
            </span>
            <span>
              <BiCurrentLocation className="jobDescription-icon" /> {location}
            </span>
          </div>
        </div>
      </div>

      <div className="jobDescription-content">
        <p>{description}</p>

        {skillsNeeded && (
          <div className="skillsNeeded">
            <h3>Skills Needed:</h3>
            <ul>
              {skillsNeeded.map((skill, skillId) => (
                <li style={{ listStyleType: "square" }} key={skillId}>
                  {skill}
                </li>
              ))}
            </ul>
          </div>
        )}

        {mustHave && (
          <div className="mustHave">
            <h3>Must Have:</h3>
            <ul>
              {mustHave.map((must, mustId) => (
                <li style={{ listStyleType: "square" }} key={mustId}>
                  {must}
                </li>
              ))}
            </ul>
          </div>
        )}

        {experience && (
          <div className="experience">
            <h3>Experience:</h3>
            <ul>
              {experience.map((exp, expId) => (
                <li style={{ listStyleType: "square" }} key={expId}>
                  {exp}
                </li>
              ))}
            </ul>
          </div>
        )}

        {education && (
          <div className="education">
            <h3>Education:</h3>
            <ul>
              {education.map((edu, eduId) => (
                <li style={{ listStyleType: "square" }} key={eduId}>
                  {edu}
                </li>
              ))}
            </ul>
          </div>
        )}
        {renumeration && (
          <div className="renumeration">
            <h3>Renumeration:</h3>
            <ul>
              {renumeration.map((renu, renuId) => (
                <li style={{ listStyleType: "square" }} key={renuId}>
                  {renu}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="jobDescription-buttons">
          <button className="jobDescription-btn1" onClick={applyJob}>
            Apply
          </button>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* <button
            className="jobDescription-btn2"

            // onClick={shareJob}
          >
            Share
          </button> */}
          {/* <Button bgColor="rgb(3,59,61)" moveTo="/contactus">
            Apply
          </Button>
          <Button
            bgColor="rgb(3,59,61)"
            isOutline={true}
            color="green"
            moveTo="/contactus"
          >
            Share
          </Button> */}
        </div>
        <Modal
          isOpen={isModalOpen}
          style={modalStyles}
          onRequestClose={closeModal}
        >
          <AiOutlineClose className="modal-closeIcon" onClick={closeModal} />
          <div className="modal-title">
            <h2>Job: {title}</h2>
          </div>
          <form className="modal-form">
            <input
              className="modal-form-input1"
              type="text"
              placeholder="your name..."
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <input
              className="modal-form-input2"
              type="email"
              placeholder="your email..."
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              className="modal-form-input3"
              type="text"
              placeholder="your phone..."
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <textarea
              className="modal-form-input4"
              type="textarea"
              rows="4"
              placeholder="your Message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />

            <div className="modal-form-button-cont">
              <button className="modal-form-button" onClick={submitJob}>
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default JobDescription;
