import React from "react";
import { Container, Picture } from "./ImageElement";

function Image({ width, height, src }) {
  return (
    <Container width={width} height={height}>
      <Picture src={src} alt="hello"></Picture>
    </Container>
  );
}

export default Image;
