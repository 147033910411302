import styled from "styled-components";
import { Link } from "react-router-dom";

export const BtnLink = styled(Link)``;
export const Btn = styled.button`
  padding: 8px 20px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  background-color: ${({ isOutline, bgColor, color }) =>
    isOutline ? "transparent" : bgColor || "rgb(222, 10, 10)"};
  color: ${({ color }) => color || "white"};

  font-size: 1.25rem;
  &:hover {
    background-color: rgb(7, 21, 86);
    color: white;
    transition: all 0.3s ease-out;
    transition: 250ms;
  }
  border: ${({ isOutline, color }) => {
    return isOutline ? `1px solid ${color}` : "none";
  }};
`;
