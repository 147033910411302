import logo from "../images/logo.svg";
import award from "../images/award.svg";

const data = [
  {
    header: "Innovation",
    paragraph:
      "We are a company of thoughts and technology is just a means. Our innovative ideas provide effective solutions to the enterprises.",
    src: "bulb",
  },
  {
    header: "Dynamicity",
    paragraph:
      "Our dynamic nature of adapting to different environments and technologies makes us to think out of box in a productive and progressive way",
    src: "spark",
  },
  {
    header: "Creativity",
    paragraph:
      "We provide an ideal platform to employ creativity and ideas apart from technological developments",
    src: "bulb",
  },
  {
    header: "Sincerity",
    paragraph:
      "Sincerity in fulfilling the requirements with on time delivery. ",
    src: "trust",
  },
];

export default data;
