import styled from "styled-components";
export const CardImageContainer = styled.div`
  background-color: rgb(205, 210, 210);
  display: flex;
  flex-direction: ${({ imgFirst }) => {
    return imgFirst ? "row-reverse" : "row";
  }};
  justify-content: space-around;
  align-items: center;
  height: 90vh;
`;
