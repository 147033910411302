import React from "react";
import ContactAddr from "../elements/ContactAddr";
import ContactForm from "../elements/ContactForm";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div className="contactus-container">
      <ContactAddr />
      <ContactForm />
    </div>
  );
}

export default ContactUs;
