import React from "react";
import { CardImageContainer } from "./CardImageElement";
import three from "../../images/3.jpg";
import four from "../../images/4.jpg";
import seven from "../../images/7.jpeg";
import eight from "../../images/8.jpg";
import nine from "../../images/9.jpg";
import Card from "./Card";
import "./CardImage.css";

import { Button } from "./Button";

function CardImage({
  header,
  para,
  btnContent,
  src,
  imgFirst,
  btnColor,
  btnBgColor,
}) {
  let img;
  if (src == 3) img = three;
  if (src == 4) img = four;
  if (src == 7) img = seven;
  if (src == 8) img = eight;
  if (src == 9) img = nine;
  return (
    <div className="cardImage-container">
      {/* <Card
        height="300px"
        width="500px"
        header={header}
        paragraph={para}
        color="blue"
        bgColor="pale"
        isShadow={false}
        isButton={true}
        moveTo="/aboutus"
        isOutline={true}
        btnColor="blue"
        btnContent={btnContent}
        headerFont="2rem"
        paraFont="1.3rem"
      />
      {img && <Image src={img} width="500px" height="300px"></Image>} */}
      <div className="cardImage-row">
        <div className="cardImage-col">
          <h1>{header}</h1>
          <p>{para}</p>
          {btnContent && (
            <Button
              isOutline={false}
              moveTo="/careers"
              color={btnColor}
              bgColor={btnBgColor}
            >
              {btnContent}
            </Button>
          )}
        </div>
        <div className="cardImage-col">
          <img src={img} alt="hello" className="cardImage-img" />
        </div>
      </div>
    </div>
  );
}

export default CardImage;
