import React from "react";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
import "./Footer.css";
const linkStyle = {
  textDecoration: "none",
  marginBottom: "12px",
};
function Footer() {
  return (
    <footer>
      <div className="footer-row-1">
        <div className="footer-col logoCol">
          <h3 className="konnect">
            <span>K</span>
            <span>o</span>
            <span>n</span>
            <span>n</span>
            <span>e</span>
            <span>c</span>
            <span>t</span>
            <span>2</span>
            <span>4</span>
            <span>7</span>
          </h3>
          <img src={logo} alt="logo" className="logo" />
          <p>
            Evolving Problems... <br /> Innovating Solutions
          </p>
          <div className="footer-sm">
            <a href="https://www.facebook.com/Konnect247365">
              <FaFacebook className="footer-sm-icon" />
            </a>
            <a href="https://twitter.com/konnect247">
              <FaTwitter className="footer-sm-icon" />
            </a>
            <a href="https://www.linkedin.com/company/konnect247">
              <FaLinkedin className="footer-sm-icon" />
            </a>
            <a href="https://www.youtube.com/channel/UCkqATBrVxy_Uyc0wdgFuqEw">
              <FaYoutube className="footer-sm-icon" />
            </a>
          </div>
        </div>
        <div className="footer-col address">
          <h3>Address</h3>
          <p>Fairgate House</p>
          <p>205 Kings Road </p>
          <p> Birmingham, B11 2AA</p>
          <p> United Kingdom</p>
          <p>info@Konnect247.com</p>
          <p>Ph: +44 7587570570</p>
        </div>
        <div className="footer-col ">
          <h3>Links</h3>
          <div className="links">
            <Link to="/" className="link">
              Home
            </Link>
            <Link to="/services" className="link">
              Solutions
            </Link>
            <Link to="/aboutus" className="link">
              About Us
            </Link>
            <Link to="/careers" className="link">
              Careers
            </Link>
            <Link to="/contactus" className="link">
              Contact Us
            </Link>
            <Link to="/privacy" className="link">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-row-2">
        <div className="copyright">Copyright © 2022 ISSC</div>
      </div>
      {/* <div>
        <Link
          to="/privacy"
          style={{
            textAlign: "right",
            color: "white",
          }}
        >
          Privacy Policy
        </Link>
      </div> */}
    </footer>
  );
}

export default Footer;
