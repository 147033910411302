import React from "react";
import "./Privacy.css";
function Privacy() {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <p>
        We are a solution based company adopts this privacy policy will explain
        how our organization uses the personal data we collect from you when you
        use our website
      </p>
      <h2>What data do we collect?</h2>
      <p>Our Company collects the following data:</p>
      <ul>
        <li>
          Personal identification information (Name, email address, phone
          number, etc.)
        </li>
      </ul>
      <h2> How do we collect your data?</h2>
      <p>
        You directly provide Our Company with most of the data we collect. We
        collect data and process data when you:
      </p>
      <ul>
        <li>
          Register online or place an order for any of our products or services.
        </li>
        <li>
          Voluntarily complete a customer survey or provide feedback on any of
          our message boards or via email.
        </li>
        <li>Use or view our website via your browser’s cookies.</li>
      </ul>
      <h2> How will we use your data?</h2>
      <p>Our Company collects your data so that we can:</p>
      <ul>
        <li>Process your order and manage your account.</li>
        <li>
          Email you with special offers on other products and services we think
          you might like.
        </li>
      </ul>
      <p>
        If you agree, Our Company will share your data with our partner
        companies so that they may offer you their products and services.
      </p>
      <p>
        When Our Company processes your order, it may send your data to, and
        also use the resulting information from, credit reference agencies to
        prevent fraudulent purchases.
      </p>
      <h2>How do we store your data?</h2>
      <p>
        Our Company securely stores your data at <a href="one.com">one.com</a>
        webhosting service.
      </p>
      <p>
        Our Company will keep your basic information collected from our website
        forms for a period of one year.Once this time period has expired, we
        will delete your data by we will delete the data stored on mails.
      </p>
      <h2>Marketing</h2>
      <p>
        Our Company would like to send you information about products and
        services of ours that we think you might like, as well as those of our
        partner companies. If you have agreed to receive marketing, you may
        always opt out at a later date. You have the right at any time to stop
        Our Company from contacting you for marketing purposes or giving your
        data to other members of the Our Company Group. If you no longer wish to
        be contacted for marketing purposes, please click here.
      </p>
      <h2>What are your data protection rights?</h2>
      <p>
        Our Company would like to make sure you are fully aware of all of your
        data protection rights. Every user is entitled to the following:
      </p>
      <ul>
        <li>
          <b>**The right to access** </b>– You have the right to request Our
          Company for copies of your personal data. We may charge you a small
          fee for this service.
        </li>
        <li>
          <b>**The right to rectification** </b>– You have the right to request
          that Our Company correct any information you believe is inaccurate.
          You also have the right to request Our Company to complete the
          information you believe is incomplete.
        </li>
        <li>
          <b>**The right to erasure** </b>– You have the right to request that
          Our Company erase your personal data, under certain conditions.
        </li>
        <li>
          <b>**The right to restrict processing** </b>– You have the right to
          request that Our Company restrict the processing of your personal
          data, under certain conditions.
        </li>
        <li>
          <b>**The right to object to processing** </b>– You have the right to
          object to Our Company’s processing of your personal data, under
          certain conditions.
        </li>
        <li>
          <b>**The right to data portability** </b>– You have the right to
          request that Our Company transfer the data that we have collected to
          another organization, or directly to you, under certain conditions.
        </li>
      </ul>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us at our email:
        info@konnect247.com
      </p>
      <h2>Cookies</h2>
      <p>
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behavior information. When you
        visit our websites, we may collect information from you automatically
        through cookies or similar technology.
      </p>
      <p>
        For further information, visit
        <a href="allaboutcookies.org">allaboutcookies.org</a>.
      </p>
      <h2>How do we use cookies?</h2>
      <p>
        Our Company uses cookies in a range of ways to improve your experience
        on our website, including:
      </p>
      <ul>
        <li>Keeping you signed in</li>
        <li>Understanding how you use our website</li>
      </ul>
      <h2>What types of cookies do we use?</h2>
      <p>
        There are a number of different types of cookies, however, our website
        uses:
      </p>
      <ul>
        <li>
          <b>Functionality</b> – Our Company uses these cookies so that we
          recognize you on our website and remember your previously selected
          preferences. These could include what language you prefer and location
          you are in. A mix of first-party and third-party cookies are used.
        </li>
        <li>
          <b>Advertising </b> – Our Company uses these cookies to collect
          information about your visit to our website, the content you viewed,
          the links you followed and information about your browser, device, and
          your IP address. Our Company sometimes shares some limited aspects of
          this data with third parties for advertising purposes. We may also
          share online data collected through cookies with our advertising
          partners. This means that when you visit another website, you may be
          shown advertising based on your browsing patterns on our website.
        </li>
      </ul>
      <h2>How to manage cookies</h2>
      <p>
        You can set your browser not to accept cookies, and the above website
        tells you how to remove cookies from your browser. However, in a few
        cases, some of our website features may not function as a result.
      </p>
      <h2>Privacy policies of other websites</h2>
      <p>
        The Our Company website contains links to other websites. Our privacy
        policy applies only to our website, so if you click on a link to another
        website, you should read their privacy policy.
      </p>
      <h2>Changes to our privacy policy</h2>
      <p>
        Our Company keeps its privacy policy under regular review and places any
        updates on this web page. This privacy policy was last updated on 9
        January 2019.
      </p>
      <h2>How to contact us</h2>
      <p>
        If you have any questions about Our Company’s privacy policy, the data
        we hold on you, or you would like to exercise one of your data
        protection rights, please do not hesitate to contact us.
      </p>
      <p>
        Email us at:
        <a href="info@konnect247.com">info@konnect247.com</a>
      </p>
    </div>
  );
}

export default Privacy;
