import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
import "./ContactAddr.css";
import Box from "@mui/material/Box";
function ContactAddr() {
  return (
    <div className="contactAddr-container">
      <h3 className="contactAddr-title">Contact Us</h3>
      <div>
        <div
          style={{ margin: "20px", padding: "10px", textAlign: "left" }}
          className="contactAddr-phone"
        >
          <BsTelephoneFill style={{ marginRight: "10px" }} />{" "}
          <span>+44 7587570570</span>
        </div>
        <div
          style={{ margin: "20px", padding: "10px", textAlign: "left" }}
          className="contactAddr-email"
        >
          <MdEmail style={{ marginRight: "10px" }} />{" "}
          <span>info@Konnect247.com</span>
        </div>
        <div
          style={{ margin: "20px", padding: "10px", textAlign: "left" }}
          className="contactAddr-address"
        >
          <FaRegAddressCard style={{ marginRight: "10px" }} />
          <span>205 Kings Road, Birmingham, B11 2AA United Kingdom</span>
        </div>
      </div>
      <div style={{ margin: "20px", padding: "10px", textAlign: "left" }}>
        <a
          style={{ margin: "20px", fontSize: "1.5rem" }}
          href="https://www.facebook.com/Konnect247365"
        >
          <FaFacebook className="contactAddr-sm" />
        </a>
        <a
          style={{ margin: "20px", fontSize: "1.5rem" }}
          href="https://twitter.com/konnect247"
        >
          <FaTwitter className="contactAddr-sm" />
        </a>
        <a
          style={{ margin: "20px", fontSize: "1.5rem" }}
          href="https://www.linkedin.com/company/konnect247"
        >
          <FaLinkedin className="contactAddr-sm" />
        </a>
        <a
          style={{ margin: "20px", fontSize: "1.5rem" }}
          href="https://www.youtube.com/channel/UCkqATBrVxy_Uyc0wdgFuqEw"
        >
          <FaYoutube className="contactAddr-sm" />
        </a>
      </div>
    </div>
  );
}

export default ContactAddr;
