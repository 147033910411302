import React from "react";
// import "./Button.css";
import { Link } from "react-router-dom";
import { Btn, BtnLink } from "./ButtonElement";

const STYLES = ["btn--primary", "btn--outline", "btn--test"];

const SIZES = ["btn--medium", "btn--large"];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  moveTo,
  isOutline,
  bgColor,
  color,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <BtnLink to={moveTo}>
      <Btn isOutline={isOutline} bgColor={bgColor} color={color}>
        {children}
      </Btn>
    </BtnLink>
    // <Link to="/sign-up" className="btn-mobile">
    //   <button
    //     className={`btn ${checkButtonStyle} ${checkButtonSize}`}
    //     onClick={onClick}
    //     type={type}
    //   >
    //     {children}
    //   </button>
    // </Link>
  );
};
