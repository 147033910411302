import React from "react";
import CardImage from "../elements/CardImage";
import Jobs from "../elements/Jobs";
import "./Careers.css";
const header = "Join Our Team";
const para =
  "Konnect247 will serve you the right platform where the expertise grows with the growth of the firm. Our adaptable and dynamic approach will make you empowered professionally and personally.";

function Careers() {
  return (
    <div className="careers-container">
      <section className="careers-section-1">
        <h1 className="careers-section-1-h1">Who We Are</h1>
        <CardImage
          header={header}
          para={para}
          src={8}
          // btnContent={btnContent}
          btnBgColor="rgb(3, 59, 61)"
        />
      </section>
      <section className="careers-section-2">
        <h1 className="jobs-title">Jobs</h1>
        <div className="jobs-content">{<Jobs />}</div>
      </section>
    </div>
  );
}

export default Careers;
