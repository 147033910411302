import React, { useState } from "react";
import styled from "styled-components";
import "./Snippet.css";

function Snippet({ icon, header, para }) {
  const AA = icon;

  return (
    <div className="snippet-container">
      <AA className="snippet-icon" />
      <h2 className="snippet-header">{header}</h2>
      <p className="snippet-para">{para}</p>
    </div>
  );
}

export default Snippet;
