import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import logo from "../../images/logo.png";

function NavBar() {
  const [click, setClick] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const toggleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  const navBarStyle = isScroll ? "navbar scroll" : "navbar";
  const navMenuStyle = isScroll ? "navmenuScroll" : "navmenuNoscroll";
  const navLogo = isScroll ? "navLogoScroll" : "navLogoNoscroll";
  const navFaIcon = isScroll ? "navFaScroll" : "navFaNoScroll";
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener("resize", showButton);
  return (
    <>
      <nav className={`${navBarStyle}`}>
        <div className="navbar-container">
          <NavLink to="/" className={`navbar-logo `} onClick={closeMobileMenu}>
            <img
              src={logo}
              alt="Konnect247"
              style={{ width: "5rem", height: "auto", marginRight:"10px" }}
            />
            <span className={navLogo}> Konnect247</span>
            {/* <i className="fab fa-typo3" /> */}
          </NavLink>
          <div onClick={toggleClick} className="menu-icon">
            <i
              className={
                click
                  ? `fa-solid fa-xmark ${navFaIcon}`
                  : `fa-solid fa-bars ${navFaIcon} `
              }
            />
          </div>
          <ul className={click ? `nav-menu active` : `nav-menu`}>
            <li className="nav-item">
              <NavLink to="/" className="nav-links" onClick={closeMobileMenu}>
                <span className={navMenuStyle}>HOME</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/services"
                className={`nav-links `}
                onClick={closeMobileMenu}
              >
                <span className={navMenuStyle}>SOLUTIONS</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/careers"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <span className={navMenuStyle}>CAREERS</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/aboutus"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <span className={navMenuStyle}>ABOUT US</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/contactus"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <span className={navMenuStyle}>CONTACT US</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default NavBar;

// <Nav>
//       <NavLink to="/">
//         <img src={logo} alt="logo" />
//       </NavLink>
//       <Bars />
//       <NavList>
//         <NavLinkMobile to="/" activeStyle>
//           HOME
//         </NavLinkMobile>
//         <NavLinkMobile to="/">SOLUTIONS</NavLinkMobile>
//         <NavLinkMobile to="/">CAREERS</NavLinkMobile>
//         <NavLinkMobile to="/">ABOUT US</NavLinkMobile>
//         <NavLinkMobile to="/">CONTACT US</NavLinkMobile>
//       </NavList>
//       <NavMenu>
//         <NavLink to="/" activeStyle>
//           HOME
//         </NavLink>
//         <NavLink to="/">SOLUTIONS</NavLink>
//         <NavLink to="/">CAREERS</NavLink>
//         <NavLink to="/">ABOUT US</NavLink>
//         <NavLink to="/">CONTACT US</NavLink>
//       </NavMenu>
//     </Nav>
