import React, { useState, useEffect } from "react";
import "./JobElement.css";
import { FaSuitcase } from "react-icons/fa";
import { BiCurrentLocation } from "react-icons/bi";
function JobElement({ element, onSelectJob, isSelected }) {
  const {
    id,
    title,
    type,
    location,
    date,
    description,
    skillsNeeded,
    mustHave,
    experience,
    education,
    renumeration,
  } = element;

  const setBgColor = isSelected ? "selectedBgm" : "not-selectedBgm";
  const setColor = isSelected ? "selectedColor" : "not-selectedColor";
  return (
    <div
      onClick={() => {
        onSelectJob(id);
      }}
      className={`jobElement-container ${setBgColor} ${setColor}`}
    >
      <div className="jobElement-snippet">
        <div className="jobElement-snippet-title">{title}</div>
        <div className="jobElement-snippet-type">
          <FaSuitcase />
          <span>{type}</span>
        </div>
        <div className="jobElement-snippet-location">
          <BiCurrentLocation />
          <span>{location}</span>
        </div>
      </div>
    </div>
  );
}

export default JobElement;

{
  /* <div className="job-section">
     <div className="job-title" onClick={toggleExpand}>
       <div className="job-title-text">
         <h2>{title}</h2>
         <p>- {date} </p>
       </div>
       <FaPlus />
     </div>
     {isExpanded && (
       <div className="job-content">
         <p>{description}</p>

         {skillsNeeded && (
           <div className="skillsNeeded">
             <h3>Skills Needed:</h3>
             <ul>
               {skillsNeeded.map((skill, skillId) => (
                 <li style={{ listStyleType: "square" }} key={skillId}>
                   {skill}
                 </li>
               ))}
             </ul>
           </div>
         )}

         {mustHave && (
           <div className="mustHave">
             <h3>Must Have:</h3>
             <ul>
               {mustHave.map((must, mustId) => (
                 <li style={{ listStyleType: "square" }} key={mustId}>
                   {must}
                 </li>
               ))}
             </ul>
           </div>
         )}

         {experience && (
           <div className="experience">
             <h3>Experience:</h3>
             <ul>
               {experience.map((exp, expId) => (
                 <li style={{ listStyleType: "square" }} key={expId}>
                   {exp}
                 </li>
               ))}
             </ul>
           </div>
         )}

         {education && (
           <div className="education">
             <h3>Education:</h3>
             <ul>
               {education.map((edu, eduId) => (
                 <li style={{ listStyleType: "square" }} key={eduId}>
                   {edu}
                 </li>
               ))}
             </ul>
           </div>
         )}
         {renumeration && (
           <div className="renumeration">
             <h3>Renumeration:</h3>
             <ul>
               {renumeration.map((renu, renuId) => (
                 <li style={{ listStyleType: "square" }} key={renuId}>
                   {renu}
                 </li>
               ))}
             </ul>
           </div>
         )}
       </div>
     )}
   </div> */
}
