import "./App.css";
import NavBar from "./component/nav/NavBar";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./component/pages/Home";
import Services from "./component/pages/Services";
import Careers from "./component/pages/Careers";
import ContactUs from "./component/pages/ContactUs";
import AboutUs from "./component/pages/AboutUs";
import Footer from "./component/elements/Footer";
import Privacy from "./component/pages/Privacy";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { color } from "@mui/system";
import ScrollToTop from "./component/elements/ScrollToTop";

function App() {
  return (
    <Router>
      <NavBar />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </ScrollToTop>
      <Footer />
      <CookieConsent
        style={{ backgroundColor: "rgb(7, 21, 86)", textAlign: "center" }}
        debug={true}
        expires={365}
      >
        We use cookies on our website to give you the most relevant experience
        by remembering your preferences and repeat visits. You may visit{"   "}
        <Link
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "white",
          }}
          to="/privacy"
        >
          Privacy Policy
        </Link>
        {"   "}for further details.
      </CookieConsent>
    </Router>
  );
}

export default App;
