import React from "react";
import three from "../../images/4.jpg";
import Card from "../elements/Card";
import "./Services.css";
import { DiCode } from "react-icons/di";
import { RiBuilding2Fill, RiHotelFill } from "react-icons/ri";
import { FiTruck } from "react-icons/fi";
import { BsPeople } from "react-icons/bs";
import { HiOutlineSparkles } from "react-icons/hi";
import { GiCyberEye, GiHumanPyramid } from "react-icons/gi";
import { MdVoicemail } from "react-icons/md";
import data from "../../data/data";
import { FaLaptopCode, FaPeopleArrows, FaPalette } from "react-icons/fa";
import { SiTrustpilot } from "react-icons/si";
import { Button } from "../elements/Button";
import Image from "../elements/Image";
import CardImage from "../elements/CardImage";
import Snippet from "../elements/Snippet";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import "swiper/swiper.min.css";
// import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
// import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/navigation/navigation.min.css";
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/core";
import { FaRegLightbulb } from "react-icons/fa";
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const Service = [
  {
    header: "Konnect247",
    para: "Our solutions are innovative, creative and critical out of box thinking. We provide solutions in a wide range covering almost every facet of businesses. ERP, CRM, Fi, Logistics, HR, IVR & Cyber Security are just some to name.",
    btnContent: "explore",
    moveTo: "/careers",
    src: 7,
    imgFirst: true,
  },
];
const cardData = [
  {
    icon: FaPeopleArrows,
    header: "CRM",
    para: "Improving business relationships & profitability for the betterment of customer experience with you.",
  },
  {
    icon: GiHumanPyramid,
    header: "HRMS",
    para: "Effective  management and deployment of the right people to benefit the firm.",
  },
  {
    icon: RiBuilding2Fill,
    header: "ERP",
    para: "managing core business processes to simplify operations & reducing the costs",
  },
  {
    icon: RiHotelFill,
    header: "PMS",
    para: "Effective Administration of hotels in availability, booking and others ",
  },
  {
    icon: MdVoicemail,
    header: "IVR",
    para: "Interacting customers with automated voice systems",
  },
  {
    icon: GiCyberEye,
    header: "Cyber Security",
    para: "Determining the best way to defend systems, networks, and programs from digital attacks ",
  },
  {
    icon: FaLaptopCode,
    header: "App Development",
    para: "Full stack development of web and mobile applications",
  },
];
const content = Service.map(
  ({ header, para, btnContent, src, imgFirst }, id) => {
    return (
      <>
        <CardImage
          key={id}
          header={header}
          para={para}
          // btnContent={btnContent}
          src={src}
          imgFirst={imgFirst}
          // btnColor="white"
          // btnBgColor="rgb(3, 59, 61)"
        ></CardImage>
      </>
    );
  }
);
const snippets = cardData.map(({ icon, header, para }) => (
  <Snippet icon={icon} header={header} para={para} />
));

const boxes = data.map((detail, id) => {
  const icon = detail.src;
  let iconType;
  if (icon == "bulb") iconType = FaRegLightbulb;
  if (icon == "spark") iconType = HiOutlineSparkles;
  if (icon == "trust") iconType = SiTrustpilot;
  return (
    <SwiperSlide className="swiper-slide">
      <Card
        key={id}
        height="100%"
        width="100%"
        // src={detail.src}
        header={detail.header}
        paragraph={detail.paragraph}
        icon={iconType}
        iconColor="orange"
        color="white"
        bgColor="rgba(82, 103, 194, 0.6)"
        isShadow={false}
        isButton={false}
        moveTo="/careers"
        btnBgColor="#9db9ee"
        isBorder={false}
      />
    </SwiperSlide>
  );
});

function Services() {
  return (
    <div className="services-container">
      <section className="service-1">{content}</section>

      <section className="service-2">
        <h1>Our Solutions</h1>
        <div className="row">{snippets}</div>
      </section>
      <section className="service-3">
        <div className="service-3-head">
          <h1>What Makes us Different?</h1>
        </div>
        <div className="service-3-features">
          <Swiper
            navigation={true}
            effect={"coverflow"}
            centeredSlides={true}
            slidesPerView={window.innerWidth < 768 ? 1 : 2}
            loop={true}
            coverflowEffect={
              window.innerWidth < 768
                ? {
                    rotate: 50,
                    stretch: 0,
                    depth: 50,
                    modifier: 1,
                    slideShadows: false,
                  }
                : {
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }
            }
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {boxes}
          </Swiper>
        </div>
      </section>
    </div>
  );
}

export default Services;

// import React, { useRef, useState } from "react";
// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import "swiper/css/pagination";

// import "./styles.css";

// // import required modules
// import { EffectCoverflow, Pagination } from "swiper";

// export default function App() {
//   return (
//     <>
//       <Swiper
//         effect={"coverflow"}
//         grabCursor={true}
//         centeredSlides={true}
//         slidesPerView={"auto"}
//         coverflowEffect={{
//           rotate: 50,
//           stretch: 0,
//           depth: 100,
//           modifier: 1,
//           slideShadows: true,
//         }}
//         pagination={true}
//         modules={[EffectCoverflow, Pagination]}
//         className="mySwiper"
//       >
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
//         </SwiperSlide>
//       </Swiper>
//     </>
//   );
// }

// #app { height: 100% }
// html,
// body {
//   position: relative;
//   height: 100%;
// }

// body {
//   background: #eee;
//   font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
//   font-size: 14px;
//   color: #000;
//   margin: 0;
//   padding: 0;
// }

// .swiper {
//   width: 100%;
//   padding-top: 50px;
//   padding-bottom: 50px;
// }

// .swiper-slide {
//   background-position: center;
//   background-size: cover;
//   width: 300px;
//   height: 300px;
// }

// .swiper-slide img {
//   display: block;
//   width: 100%;
// }
