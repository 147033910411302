import React from "react";
import "./SwipeElement.css";
function SwipeElement({ src, width, height }) {
  return (
    <div
      style={{ width: width || "150px", height: height || "150px" }}
      className="swipe-container"
    >
      <img src={src} alt="img" />
    </div>
  );
}

export default SwipeElement;
