import React from "react";
import { Button } from "./Button";
import { CardWrapper, Content, Header, IconWrapper, Para } from "./CardElement";

function Card({
  height,
  width,
  header,
  paragraph,
  icon,
  color,
  bgColor,
  iconColor,
  isShadow,
  isButton,
  moveTo,
  isOutline,
  btnBgColor,
  btnColor,
  btnContent,
  isBorder,
  headerFont,
  paraFont,
}) {
  const AA = icon;
  return (
    <CardWrapper
      height={height}
      width={width}
      bgColor={bgColor}
      isShadow={isShadow}
      color={color}
      isBorder={isBorder}
    >
      {icon && (
        <IconWrapper>
          <AA style={{ color: iconColor || "white", fontSize: "2.5rem" }} />
        </IconWrapper>
      )}
      <Content>
        <Header headerFont={headerFont}>{header}</Header>
        <Para paraFont={paraFont}>{paragraph}</Para>
      </Content>
      {isButton && (
        <Button
          moveTo={moveTo}
          isOutline={isOutline}
          bgColor={btnBgColor}
          color={btnColor}
        >
          {btnContent}
        </Button>
      )}
    </CardWrapper>
  );
}

export default Card;
//

//
