import styled from "styled-components";
export const QuoteWrapper = styled.div`
  box-sizing: border-box;
  width: ${({ width }) => width || "300px"};
  height: ${({ height }) => height || "400px"};
  background-color: ${({ bgColor }) => bgColor || "pale"};
  color: ${({ color }) => color || "black"};
  border: 20px solid ${({ color }) => color || "brown"};
  border-radius: 4%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 1.2rem;
`;
export const QuoteSpan = styled.span`
  font-size: 4rem;
  color: ${({ color }) => color || "black"};
  font-weight: bold;
  font-family: georgia;
`;
