import React, { useState, useEffect } from "react";
import CardImage from "../elements/CardImage";
import SwipeElement from "../elements/SwipeElement";
import vf from "../../images/vodafone.png";
import steria from "../../images/steria.png";
import ukvi from "../../images/ukvi.png";
import tcs from "../../images/tcs.png";
import integra from "../../images/integra.png";
import fca from "../../images/fca.png";
import "./AboutUs.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const array = [vf, steria, ukvi, tcs, integra, fca];

const header = "We are a company of thoughts";
const para =
  "We are a solutions based company. We pride ourselves in delivering cutting edge solutions which continuously evolve using the latest technology. Our bespoke solutions take care of a wide range of almost every facet of businesses. ERP, CRM, Fi, Logistics, HR Process and Cyber Security are just some to name. IVR, AI, Deep Learning, Machine Learning, are some of the technologies that we specialize to make life easier and businesses successful.";
// const btnContent = "explore";

function AboutUs() {
  const AA = array.map((element) => (
    <div
      style={{
        width: "200px",
        height: "200px",
        display: "flex",
        alignItems: "center",
        margin: " auto",
        justifyContent: "center",
        marginLeft: "2rem",
        marginRight: "2rem",
      }}
    >
      <img src={element} style={{ width: "100%", height: "auto" }} alt="" />
    </div>
  ));

  return (
    <div className="aboutus-container">
      <div className="aboutus-section1">
        <h1 className="aboutus-h1">Who We Are</h1>
        <CardImage
          header={header}
          para={para}
          src={9}
          // btnContent={btnContent}
          btnBgColor="rgb(3, 59, 61)"
        />
      </div>

      <div className="aboutus-section2">
        <h1 className="aboutus-h1A">Our clients</h1>
        <div className="aboutus-section2-clientImages">{AA}</div>
      </div>
    </div>
  );
}

export default React.memo(AboutUs);
